/* Reset */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Base styles */

html,
body {
  font-family: "Roboto-Regular";
  color: #424242;
  background: #eeeeee;
  font-size: 16px; // for easy PX to REM conversion. We apply .body-1 as default
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

sup {
  font-size: 70%;
}
