@font-face {
  font-family: 'Roboto-Regular';
  src: url('../assets/fonts/Roboto-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff'),
    url('../assets/fonts/Roboto-Regular.ttf') format('truetype'),
    url('../assets/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono-Regular';
  src: url('../assets/fonts/RobotoMono-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/RobotoMono-Regular.woff') format('woff'),
    url('../assets/fonts/RobotoMono-Regular.ttf') format('truetype'),
    url('../assets/fonts/RobotoMono-Regular.svg#RobotoMono-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono-Bold';
  src: url('../assets/fonts/RobotoMono-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/RobotoMono-Bold.woff') format('woff'),
    url('../assets/fonts/RobotoMono-Bold.ttf') format('truetype'),
    url('../assets/fonts/RobotoMono-Bold.svg#RobotoMono-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('../assets/fonts/Lora.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Lora.woff') format('woff'),
    url('../assets/fonts/Lora.ttf') format('truetype'),
    url('../assets/fonts/Lora.svg#Lora') format('svg');
  font-weight: normal;
  font-style: normal;
}
